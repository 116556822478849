import { Grid } from '@mui/material'
import React from 'react'
import SectionLayout from '../SharedComponents/SectionLayout'
import ArticleCard, { ArticleCardProps } from './ArticleCard'

type ArticlesGridProps = {
  articles: ArticleCardProps[]
}

const ArticlesGrid = (props: ArticlesGridProps) => {
  const { articles } = props
  return (
    <SectionLayout name="articles" backgroundColor="trustBlue.main">
      <Grid container spacing={3}>
        {articles.map((article, idx) => (
          <ArticleCard key={article.slug} {...article} delay={idx * 100} />
        ))}
      </Grid>
    </SectionLayout>
  )
}

export default ArticlesGrid
