import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ArticlesGrid from '../components/BlogComponents/ArticlesGrid'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import BlogHero from '../components/BlogComponents/BlogHero'
import { useRecordPageDuration } from '../components/utils'

const pageTitle = 'Flock | Blog'
const pageDescription =
  'Confused about real estate investing? Read articles written by our real estate experts.'

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Blog = () => {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { order: DESC, fields: publishedAt }) {
        nodes {
          ...ArticleCard
        }
      }
    }
  `)

  useRecordPageDuration()

  return (
    <PageWrapper
      title="Flock | Blog"
      trackingName="blog"
      headerBackground="trustBlue.main"
    >
      <BlogHero />
      <ArticlesGrid articles={allStrapiArticle.nodes} />
    </PageWrapper>
  )
}

export default Blog
